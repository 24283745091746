import React from 'react'
import Helmet from 'react-helmet'
import { SITE_TITLE, intl } from 'src/constants'
import Ogp from 'components/atoms/Ogp'
import ShowMoreArticleList from 'components/ShowMoreArticleList'
import Footer from 'components/Footer'

export default ({ pageContext }) => {
  const title =
    pageContext.pageMeta.CLASS_NAME === 'categories' ||
    pageContext.pageMeta.CLASS_NAME === 'tags'
      ? pageContext.pageMeta.TITLE
      : intl.getMessage(pageContext.pageMeta.TITLE)

  const articleLength = pageContext.articles.length === 1 ? 'oneArticle' : ''

  // フォントサイズの初期値が80px、字間の初期値が20px
  let titleFontSize = 80
  let titleLetterSpacing = 20
  // タグの文字数に応じてサイズが変化
  if (0 < title.length && title.length < 6) {
    titleFontSize = 80
    titleLetterSpacing = 20
  } else if (6 <= title.length && title.length < 8) {
    titleFontSize = 60
    titleLetterSpacing = 15
  } else if (8 <= title.length && title.length < 13) {
    titleFontSize = 40
    titleLetterSpacing = 10
  } else {
    titleFontSize = 32
    titleLetterSpacing = 8
  }

  return (
    <>
      <div className="white_space_article">
        <div
          className="title"
          style={{
            fontSize: `${titleFontSize}px`,
            letterSpacing: `${titleLetterSpacing}px`,
          }}
        >
          {pageContext.pageMeta.CLASS_NAME === 'tags' && <span>#</span>}
          {title}
        </div>
      </div>
      <div
        className={`${pageContext.pageMeta.CLASS_NAME} articleArea ${articleLength}`}
      >
        <Helmet title={`${title} | ${SITE_TITLE}`} />
        <Ogp title={title} postUrl={pageContext.pageMeta.POST_URL} />
        <ShowMoreArticleList
          title={title}
          className={pageContext.pageMeta.CLASS_NAME}
          articles={pageContext.articles}
          adbanners={pageContext.adbanners}
          articles_type={pageContext.type}
          is_articles_page
          show_more_link="/"
        />
      </div>
      <Footer displayMenu />
    </>
  )
}
